<template>
  <v-card v-if="delivery" :class="statusClass(delivery.status)" class="mb-5">
    <v-row class="body-2">
      <v-col cols="2" sm="1">
        <router-link :to="getOrder(delivery.manifest_items)"
          >{{ getOrderId(delivery.manifest_items) }}
        </router-link>
      </v-col>
      <v-col cols="2" md="2"
        >{{ delivery.id }}
        <v-btn
          v-if="canCancel"
          icon
          color="red"
          @click.prevent="cancelDeliviery(delivery.id)"
          ><v-icon>mdi-cancel</v-icon></v-btn
        ></v-col
      >

      <v-col cols="3" md="2" lg="1">{{ delivery.status }}</v-col>
      <v-col cols="5" md="4" lg="2">
        <v-sheet>
          <v-row no-gutters> {{ delivery.external_id }} </v-row>
          <v-row no-gutters>
            Pickup Ready: {{ convertTime(delivery.pickup_ready) }}
          </v-row>
          <v-row no-gutters v-if="delivery.pickup_eta">
            Pickup Eta: {{ convertTime(delivery.pickup_eta) }}
          </v-row>
          <v-row no-gutters v-if="delivery.dropoff_eta">
            Dropoff Eta: {{ convertTime(delivery.dropoff_eta) }}
          </v-row>

          <PmDriver :delivery="delivery" />
        </v-sheet>
      </v-col>

      <v-col cols="6" md="5" lg="3"
        ><PmAddress :address="delivery.pickup"
      /></v-col>
      <v-col cols="6" md="5" lg="3"
        ><PmAddress :address="delivery.dropoff"
      /></v-col>
      <v-col cols="2" lg="1"> </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import firebase from '@/firebase-init'

export default {
  props: {
    delivery: {
      type: Object,
      default: null
    },
    backend: {
      type: String,
      default: null
    }
  },
  components: {
    PmAddress: () => import('./PmAddress.vue'),
    PmDriver: () => import('./PmDriver.vue')
  },
  computed: {
    canCancel() {
      return (
        this.delivery.status !== 'canceled' &&
        this.delivery.status !== 'delivered'
      )
    }
  },
  methods: {
    convertTime(utc) {
      if (moment(utc).isSame(moment(), 'day')) {
        return moment(utc).format('hh:mm A')
      }
      return `${moment(utc).format('MM/DD/YYYY hh:mm A')} `
    },
    statusClass(status) {
      switch (status) {
        case 'pending':
          return 'info--text'
        case 'pickup':
          return 'primary--text'
        case 'delivered':
          return 'black--text'
        case 'pickup_complete':
          return 'accent--text'
        case 'dropoff':
          return 'cyan--text'
        default:
          break
      }
    },
    getOrder(manifest_items) {
      const data =
        manifest_items && manifest_items.length
          ? manifest_items.find(m => m.name.indexOf('_') >= 0)
          : null

      const orderId = data && data.name ? data.name.split('_')[0] : null
      const uid = data && data.name ? data.name.split('_')[1] : null

      return `/order/view/${uid}/${orderId}?source=buyer`
    },
    getOrderId(manifest_items) {
      const data =
        manifest_items && manifest_items.length
          ? manifest_items.find(m => m.name.indexOf('_') >= 0)
          : null

      const orderId = data && data.name ? data.name.split('_')[0] : null

      return orderId ? orderId.substr(0, 4) : null
    },
    async cancelDeliviery(id) {
      if (confirm('Are you sure want to cancel this delivery?')) {
        const token = await firebase.auth().currentUser.getIdToken()

        const targetUrl = `${this.backend}/pm/cancel?id=${id}`
        await axios
          .post(
            targetUrl,
            {},
            {
              headers: {
                Authorization: 'Bearer ' + token
              }
            }
          )
          .then(d => {
            if (d.status == 200) {
              this.$store.dispatch('setMessage', {
                title: 'Success',
                body: 'Delivery Cancelled'
              })
            }
          })

          .catch(e => {
            console.error(e)
          })
        this.$emit('getList')
      }
    }
  }
}
</script>
